const buyURL = "/buy/"
const sellURL = "/sell/"
const rentURL = "/rent/"
const offPlanURL = "/off-plan/"
const servicesURL = "/property-services/"
const aboutURL = "/about-us/"
const teamURL = `${aboutURL}our-team/`
const propertiesURL = "/properties/"
const propertyValuation = "/property-valuation/"
const resources = "/resources/"
const blog = `${resources}latest-news-and-insights/`
const guidesUrl = "/guides/"
const areaguideURL = `${guidesUrl}area-guides-dubai/`
const offplan="/off-plan-properties/"
const commercialURL="/commercial-properties/"
const reviewUrl= "/about-us/client-reviews/"


module.exports = {
  blog,
  offplan,
  commercialURL,
  reviewUrl,
  teamURL,
  propertyValuation,
  areaguideURL,
  marketreportURL: "/resources/market-reports/",
  sitemapURL: "/sitemap/",
  privacyPolicyURL: "/privacy-policy/",
  termsAndConditionsURL: "/terms-and-conditions/",
  cookiePolicyURL: "/cookie-policy/",
  propertiesURL,
  propertiesSaleURL: `${propertiesURL}for-sale/`,
  propertiesRentURL: `${propertiesURL}to-rent/`,
  propertyDetailSaleURL: "/properties-for-sale/",
  propertyDetailRentURL: "/properties-to-rent/",
  offplanResultsUrl: `/off-plan-properties/for-sale/in-dubai/`,
  homePageURL: "/",
  buyURL,
  whyBuyWithUsURL: `${buyURL}why-buy-property-with-us/`,
  mortgageCalculatorURL: `${buyURL}mortgage-calculator/`,
  guidesBuyURL: `${buyURL}property-buying-guide/`,
  sellURL,
  sellYourPropertyURL: `${sellURL}sell-your-property/`,
  whySellWithUsURL: `${sellURL}why-sell-property-with-us/`,
  guidesSellURL: `${sellURL}property-selling-guide/`,
  rentURL,
  whyRentWithUsURL: `${rentURL}why-rent-property-with-us/`,
  rentalYieldCalculatorURL: `${rentURL}rental-yield-calculator/`,
  guidesRentURL: `${rentURL}property-renting-guide/`,
  offPlanURL,
  whyOffPlanWithUsURL: `${offPlanURL}why-buy-off-plan-property-with-us/`,
  mortgageAndRentalYieldCalculatorsURL: `${offPlanURL}mortgage-and-rental-yield-calculators/`,
  guidesOffPlanURL: `${offPlanURL}property-guide/`,
  servicesURL,
  leasingURL: `${offPlanURL}leasing/`,
  mortgagesURL: `${offPlanURL}mortgages/`,
  holidayHomesURL: `${offPlanURL}holiday-homes/`,
  conveyancingURL: `${offPlanURL}conveyancing/`,
  propertySnaggingURL: `${offPlanURL}property-snagging/`,
  secondCitizenshipURL: `${offPlanURL}second-citizenship/`,
  forexServicesURL: `${offPlanURL}forex-services/`,
  partialOwnershipURL: `${offPlanURL}partial-ownership/`,
  aboutURL,
  ourAwardsURL: `${aboutURL}our-awards/`,
  teamsURL: `${aboutURL}meet-the-team/`,
  reviewsURL: `${aboutURL}reviews/`,
  philanthropyURL: `${aboutURL}philanthropy/`,
  careersURL: `${aboutURL}careers/`,
  communitiesURL: `${aboutURL}communities/`,
  newsAndInsightURL: `${aboutURL}news-and-insight/`,
}
